<template>
        <v-flex md1 >
            <v-btn 
				color="primary" 
				fab
				class="ml-5"
				:small="small"
				@click="modal = true"
            >
                <v-icon dark>mdi-pencil</v-icon>
            </v-btn>
            <v-dialog v-model="modal" max-width="600">
                <v-card>
					<v-form>
						<v-card-title class="pb-0">
							<span>Edytuj zadanie</span>
							<span @click="modal = !modal" class="ml-auto cursor-pointer">X</span>
						</v-card-title>
                    <v-card-text class="pb-0">
                        <v-text-field
                            v-model="name"
                            label="Nazwa"
                            clearable
                            :error-messages="nameErrors"
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"
                            v-if="role === 'administrator' || role ===  'moderator'"

                        ></v-text-field>
                        <v-text-field
                            v-model="characters"
                            label="Liczba znaków"
                            required
                            :error-messages="characterErrors"
                            @input="$v.characters.$touch()"
                            @blur="$v.characters.$touch()"
                            clearable
                        ></v-text-field>
                        <VueEditor 
                            v-if="role === 'administrator' || role ===  'moderator'"
                            v-model="description" 
                            class="mb-4" 
                        />
                        
                    </v-card-text>

                    <p class="font-weight-regular pl-6" v-if="feedback">{{ feedback }}</p>

                    <v-card-actions class="ml-4">
                        <v-btn
                            color="primary"
                            @click="editTask"
                            :disabled="$v.$invalid"
							:loading="isLoaded"
                        >
                            Edytuj
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
            </v-dialog>
        </v-flex>
</template>

<script>
import { VueEditor } from "vue2-editor";
import { validationMixin } from 'vuelidate';
import {errorMsg} from '@/mixins/Validation';
const { required, decimal } = require('vuelidate/lib/validators')

export default{
    name: "EditTaskApprentice",
    mixins: [validationMixin, errorMsg],
    components: {
        VueEditor,
    },
    props: {
        id: { 
            type: String,
        },
        small: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        role(){
            return this.$store.getters.getUserRole;
		},
    },
    data(){
        return{
			isLoaded: false,
            modal: false,
            name: null,
            description: null,
            feedback: null,
            menu: false,
            characters: 3000,
        }
    },
    validations: {
        name: {
            required,
		},
        description: {
            required,
		},
        characters: {
            decimal,
            required
        },
    },
	methods: {
        getSingleTask(){
            this.$axios.get(`${this.url}api/apprentice-tasks/${this.id}`)
            .then(resp => {
                const { title, desciption, characters} = resp.data[0];
                this.name = title;
                this.description = desciption;
                this.characters = characters;
			})
        },
        editTask(){
            this.isLoaded = true;
            this.$axios.put(`${this.url}api/apprentice-tasks/${this.id}`, {
                title: this.name,
                desciption: this.description, 
                characters: this.characters,
                id: this.id
                
            }).then(() => {
                this.modal = false
                this.isLoaded = false;
            }).catch((err) => {
                console.log(err)
            })
        },
    },
    created() {
        this.getSingleTask()
    },

}
</script>
<template>
	<v-flex  md1>
		<v-btn
			color="error"
			class="mr-9"
			fab
            :small="small"
			@click.stop="modal = true"
			>
				<v-icon dark>mdi-trash-can-outline</v-icon>
			</v-btn>
        <v-dialog
            v-model="modal"
            max-width="400px"
        >
            <v-card>
				<v-card-title>
					Na pewno usunąć zadanie?
				</v-card-title>
				<v-card-text>
					<v-btn
						color="red"
						class="white--text mr-4"
						@click.stop="deleteTask()"
					>
						TAK
					</v-btn>
					<v-btn
						color="gray"
						class="dark--text"
						@click.stop="modal = false"
					>
						NIE
					</v-btn>
				</v-card-text>
            </v-card>
        </v-dialog>
	</v-flex>
</template>

<script>
export default {
	props: {
		id : {
			type: String
		},
		small : {
			type: Boolean
		},
		itemRole: {
			type: String
		}
	},
	data() {
		return {
			modal: false,
		}
	},
	computed: {
		userID(){
			return this.$store.getters.getUserID
		}
	
	},
	methods: {
		deleteTask(){
			this.modal = false
            this.$axios.delete(`${this.url}api/apprentice-tasks/${this.id}`)
        },
	},
}
</script>
<template>
  <div>
      <AddApprenticeTask />
      <ApprenticeTaskTable />
  </div>
</template>

<script>
import AddApprenticeTask from '@/components/molecules/AddApprenticeTask.vue';
import ApprenticeTaskTable from '@/components/templates/ApprenticeTaskTable.vue';

export default {
  components: {
    AddApprenticeTask,
    ApprenticeTaskTable
  }
}
</script>

<style>

</style>